/* eslint-disable no-console */
import React, { memo, useEffect } from 'react';
import { Theme } from '@material-ui/core';
import { withStyles, WithStyles, createStyles } from '@material-ui/core/styles';
import { jwtAppId, jitsiDomain, externalAPI } from '../../../app/config';

declare let window: any;
let _jitsi: any;

const styles = (theme: Theme) => {
  return createStyles({
    root: {
      height: '100%',
      width: '100%',
    },
  });
};

export interface IVideoConference extends WithStyles<typeof styles> {
  location: string;
  jwtToken: string;
  videoMuted: boolean;
  audioMuted: boolean;
  showScreenappForModerator: boolean;
  showScreenappForParticipants: boolean;
  setConferenceLeft: () => void;
  conferenceJoined: () => void;
  lobbyJoined: () => void;
}

const VideoConference = ({
  classes,
  location,
  jwtToken,
  videoMuted,
  audioMuted,
  showScreenappForModerator,
  showScreenappForParticipants,
  setConferenceLeft,
  conferenceJoined,
  lobbyJoined,
}: IVideoConference) => {
  const jitsiContainerId = 'video-conference-id';
  const [jitsi, setJitsi] = React.useState({
    dispose: Object,
  });

  const loadJitsiScript = () => {
    let resolveLoadJitsiScriptPromise: {
      (arg0?: boolean): any;
      (value?: unknown): void;
    } | null = null;

    const loadJitsiScriptPromise = new Promise((resolve) => {
      resolveLoadJitsiScriptPromise = resolve;
    });

    const script = document.createElement('script');
    script.src = externalAPI;
    script.async = true;
    script.onload = () => resolveLoadJitsiScriptPromise!(true);
    document.body.appendChild(script);

    return loadJitsiScriptPromise;
  };

  const initialiseJitsi = async () => {
    if (!window.JitsiMeetExternalAPI) {
      await loadJitsiScript();
    }

    _jitsi = await new window.JitsiMeetExternalAPI(jitsiDomain, {
      roomName: `${jwtAppId}/${location}`,
      jwt: jwtToken,
      parentNode: document.getElementById(jitsiContainerId),
      interfaceConfigOverwrite: {
        filmStripOnly: false,
        SHOW_JITSI_WATERMARK: false,
        SHOW_BRAND_WATERMARK: false,
        SHOW_WATERMARK_FOR_GUESTS: false,
        // DISABLE_JOIN_LEAVE_NOTIFICATIONS: true,
        // DEFAULT_BACKGROUND: '#fff',
        // INITIAL_TOOLBAR_TIMEOUT: 0,
        TOOLBAR_ALWAYS_VISIBLE: true,
        DEFAULT_REMOTE_DISPLAY_NAME: 'Fellow User',
        TOOLBAR_TIMEOUT: 10800000,
        MOBILE_APP_PROMO: false,
        SHOW_SCREENAPP_FOR_MODERATOR: showScreenappForModerator,
        SHOW_SCREENAPP_FOR_PARTICIPANTS: showScreenappForParticipants,
        // TOOLBAR_BUTTONS: ['microphone', 'camera', 'tileview'],
      },
      configOverwrite: {
        // startAudioOnly: true,
        startWithAudioMuted: audioMuted,
        startWithVideoMuted: videoMuted,
        disableRemoteMute: true,
        disableDeepLinking: true,
        // startSilent: true,
        prejoinConfig: {
          enabled: false,
        },
      },
    });

    _jitsi.addEventListener('videoConferenceLeft', () => {
      console.log('left');
      conferenceJoined();
    });
    _jitsi.addEventListener('readyToClose', () => {
      console.log('Hangup');
      setConferenceLeft();
      _jitsi.dispose();
    });
    _jitsi.addEventListener('videoConferenceJoined', (available: any) => {
      console.log('Local User Joined');
      conferenceJoined();
    });
    _jitsi.addEventListener(`videoConferenceJoined`, () => {
      _jitsi.executeCommand(`toggleTileView`);
    });
    _jitsi.addEventListener(
      `errorOccurred`,
      (details: { error: { name: string } }) => {
        if (details?.error?.name === 'conference.connectionError.membersOnly') {
          console.log('Joined to lobby');
          lobbyJoined();
        }
      }
    );

    setJitsi(_jitsi);
  };

  useEffect(() => {
    if (location) {
      initialiseJitsi();
      jitsi?.dispose?.();
    }
  }, [location]);

  return <div className={classes.root} id={jitsiContainerId} />;
};

export default memo(withStyles(styles)(VideoConference));
