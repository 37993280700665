export { default as Logo } from './jitsilogo.png';

export { default as gmailIcon } from './gmail.png';
export { default as googleIcon } from './google.png';
export { default as TrashIcon } from './trash.svg';

export { default as emptyMeetingsViewIcon } from './emptyMeetingsView.svg';
export { default as emptyOverviewViewIcon } from './emptyOverviewView.svg';
export { default as emptyUsersViewIcon } from './emptyUsersView.svg';

export { default as InstantMeetingIcon } from './instantMeeting.svg';
export { default as ScheduleMeetingIcon } from './scheduleMeeting.svg';
export { default as HelpIcon } from './help.svg';
export { default as LargeSettingsIcon } from './largeSettings.svg';

export { default as ContactInfoIcon } from './settings/Contact-info.png';
export { default as FaqIcon } from './settings/Faq.png';
export { default as GeneralSettingsIcon } from './settings/General-settings.png';
export { default as PrivacyPolicyIcon } from './settings/Privacy-policy.png';
export { default as ScreenappIcon } from './settings/screenapp.png';
export { default as TermsIcon } from './settings/terms.png';
export { default as UserProfileIcon } from './settings/User-profile.png';
export { default as PrivacyIcon } from './settings/privacy.png';

export { default as ClockIcon } from './clock.svg';

export { default as FacebookIcon } from './social/FB.svg';
export { default as InstaIcon } from './social/IG.svg';
export { default as linkedinIcon } from './social/LinkedIn.svg';
export { default as TwitterIcon } from './social/X.svg';
export { default as YoutubeIcon } from './social/Youtube.svg';
export { default as TiktokIcon } from './social/Tiktok.svg';

export { default as LocationIcon } from './location.svg';
export { default as PhoneIcon } from './phone.svg';
export { default as MailIcon } from './mail.svg';
export { default as CopyIcon } from './content_copy.svg';

export { default as StarPrimaryIcon } from './star-primary.svg';
export { default as StarGreyIcon } from './star-grey.svg';
