export const environment = process.env.REACT_APP_ENV || 'development';
export const version = process.env.REACT_APP_VERSION || '1.0.0';

export const apiBaseUrl =
  process.env.REACT_APP_API_BASE_URL || 'http://localhost:8081/v1';

export const jwtAppId = process.env.REACT_APP_JWT_APP_ID;

export const jitsiDomain = process.env.REACT_APP_JITSI_DOMAIN!;

export const webBaseUrl = process.env.REACT_APP_WEB_BASE_URL!;

export const externalAPI = process.env.REACT_APP_EXTERNAL_API_SRC!;

export const replaceRoomNameWithTitle =
  process.env.REPLACE_ROOM_NAME_WITH_TITLE || true;

export const elasticsearchApmServerUrl =
  process.env.REACT_APP_ELASTICSEARCH_APM_SERVER_URL;

export const elasticsearchApmServiceName =
  process.env.REACT_APP_ELASTICSEARCH_APM_SERVICE_NAME;
